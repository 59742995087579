import { get, post } from '@/utils/http.js';

/*
function:获取考试数据
author:ysl
time:2021/03/24
*/

export function getExamination(data: any) {
    return get('/examination/programexam', data);
}

/*
function: 签到
author:lee
time:2021/08/05
*/

export function postSign(data: any) {
    return post('/examination/sign', data);
}
